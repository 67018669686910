/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import MarkdownContent from '../components/MarkdownContent';

import '../components/contact.css';

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <div>
      <SEO title="Kontakt" />
      <Layout>
        <div className="contact">
          <div className="contact__map--container">
            <iframe
              className="contact__map--iframe"
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.6218980852722!2d17.04370474193575!3d51.10248794506283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc27b98907d6d%3A0x895e7db3af9c0b3d!2sKomuny+Paryskiej+21%2C+50-451+Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1554639721011!5m2!1spl!2spl"
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <div className="contact__info">
            <h1>{frontmatter.title}</h1>
            <p className="contact__info--item">{frontmatter.address_line_1}</p>
            <p className="contact__info--item">{frontmatter.address_line_2}</p>
            <p className="contact__info--item">tel. {frontmatter.phone_number_1}</p>
            <p className="contact__info--item">tel. {frontmatter.phone_number_2}</p>
            <p>
              e-mail:{' '}
              <a
                className="contact__info--item"
                key="mailLink"
                href={`mailto:${frontmatter.e_mail}`}
              >
                {frontmatter.e_mail}
              </a>
            </p>
            <br />
            <MarkdownContent content={frontmatter.description} />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        address_line_1
        address_line_2
        phone_number_1
        phone_number_2
        e_mail
        description
      }
    }
  }
`;
